var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentSection',{staticClass:"mt-2",attrs:{"spacing":false}},[_c('section',{staticClass:"content-spacing"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('TitlePlus',{attrs:{"title":_vm.$t('components.promoManagement.details.promoHistory.headline'),"hidePlus":true}}),_c('DateRangePicker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1),_c('div',{staticClass:"summary-card-container"},[_c('SummaryCard',{attrs:{"title":_vm.$t(
            'components.promoManagement.details.promoHistory.summary.promoUsers'
          ),"value":_vm.getSummaryUsers,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
            'components.promoManagement.details.promoHistory.summary.rentsTaken'
          ),"value":_vm.getSummaryTrips,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
            'components.promoManagement.details.promoHistory.summary.discountedAmount'
          ),"value":_vm.getSummaryDiscounts,"variant":"gray"}})],1)]),_c('FSTable',{attrs:{"fst-id":"promoDetailsHistory","headers":_vm.getTableHeaders,"endpoint":("/dashboard/promotions/" + (_vm.$route.params.id) + "/user-promotions/")},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5}}),_c('FSTableRowItem',{attrs:{"text":item.user_full_name,"to":{
                name: 'ViewRiderUserProfile',
                params: { id: item.user_id },
              },"target":"_blank"}}),_c('FSTableRowItem',{attrs:{"text":item.num_of_trips_availed}}),_c('FSTableRowItem',{attrs:{"text":item.num_of_trips_left}}),_c('FSTableRowItem',{attrs:{"text":_vm.getFormattedDateTime(item.created_at, 'D MMM, YYYY h:m a')}}),_c('FSTableRowItem',{attrs:{"text":_vm.getDiscountAmount(item.discount_amount)}})],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":item.user_full_name,"to":{
                name: 'ViewRiderUserProfile',
                params: { id: item.user_id },
              },"target":"_blank"}}),_c('FSTableRowItem',{attrs:{"text":item.num_of_trips_availed}}),_c('FSTableRowItem',{attrs:{"text":_vm.getDiscountAmount(item.discount_amount)}})],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.fleetManagement.table.columns.location'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.promoManagement.details.promoHistory.table.columns.rentLeft' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.num_of_trips_left || '--')+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.promoManagement.details.promoHistory.table.columns.date' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getFormattedDateTime(item.created_at, 'D MMM, YYYY h:m a'))+" ")])])])]):_vm._e()]:_vm._e()]})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }