<template>
  <div class="card grid grid-cols-12">
    <div class="col-span-4   ">
      <div class="w-full h-full grid grid-rows-6  p-5">
        <div class="row-span-3 flex justify-center items-end">
          <img
            class="w-15"
            src="@/assets/icon/offer/promos_icon.svg"
            alt="promo-icon"
          />
        </div>
        <div class="row-span-3 flex justify-center items-start mt-2">
          <p class="uppercase font-bold text-xl">
            {{ promoDiscountText }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-span-1 relative overflow-hidden ">
      <div class="circle-cut circle-top" />
      <div class="w-full h-full flex justify-center">
        <div class="divider border-r-2 border-dashed border-gray-200"></div>
      </div>
      <div class="circle-cut circle-bottom" />
    </div>
    <div class="col-span-7 ">
      <div class="px-5 py-6 space-y-2 ">
        <span
          class="px-5 py-1 bg-orange-100 text-orange-400 font-bold uppercase rounded-full
        "
        >
          {{ promoCode }}
        </span>
        <p class="uppercase font-bold text-xl truncate">{{ promoTitle }}</p>
        <p class="truncate text-sm text-gray-500">
          {{ promoDescription }}
        </p>
        <p class="text-sm text-gray-500">Valid till {{ promoExpiredDate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoCard',
  props: {
    promoDiscountText: {
      type: String,
      default: '30% OFF',
    },
    promoCode: {
      type: String,
      default: 'PROMOCODE',
    },
    promoTitle: {
      type: String,
      default: 'PROMO NAME',
    },
    promoDescription: {
      type: String,
      default:
        'Here you can read more detailed need information Here you can read more detailed need information',
    },
    promoExpiredDate: {
      type: String,
      default: '--',
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.card {
  min-width: 200px;
  height: 200px;
  box-shadow: 0 4px 11px 0 #3134691c;
  @apply bg-white rounded-lg;
}

.circle-cut {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 99999px;
  @apply bg-gray-100;
}
.circle-cut.circle-top {
  top: -177px;
}
.circle-cut.circle-bottom {
  bottom: -177px;
}
.divider {
  width: 0.5px;
  height: 100%;
}
.referral-code {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  text-transform: uppercase;
  color: #676773;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.code {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e2e39;
}
.copy {
  margin-top: 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #da35a1;
  cursor: pointer;
}
.h-122px {
  height: 122px;
}
.w-122px {
  width: 118px;
}
.mt-31px {
  margin-top: 35px;
}
</style>
