<template>
  <ContentSection :spacing="false" class="mt-2">
    <section class="content-spacing">
      <div class="flex items-center justify-between">
        <TitlePlus
          :title="
            $t('components.promoManagement.details.promoHistory.headline')
          "
          :hidePlus="true"
        />
        <DateRangePicker
          @apply-date="onApplyFilterDateRange"
          @cancel-date="onApplyFilterDateRange"
        />
      </div>

      <div class="summary-card-container">
        <SummaryCard
          :title="
            $t(
              'components.promoManagement.details.promoHistory.summary.promoUsers'
            )
          "
          :value="getSummaryUsers"
          variant="gray"
        />

        <SummaryCard
          :title="
            $t(
              'components.promoManagement.details.promoHistory.summary.rentsTaken'
            )
          "
          :value="getSummaryTrips"
          variant="gray"
        />

        <SummaryCard
          :title="
            $t(
              'components.promoManagement.details.promoHistory.summary.discountedAmount'
            )
          "
          :value="getSummaryDiscounts"
          variant="gray"
        />
      </div>
    </section>

    <FSTable
      :fst-id="`promoDetailsHistory`"
      :headers="getTableHeaders"
      :endpoint="`/dashboard/promotions/${$route.params.id}/user-promotions/`"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <template v-for="(item, itemIndex) in data">
          <template v-if="getTableMode === 'full'">
            <FSTableRow :key="`fs-table-row-${itemIndex}`" text-fallback-always>
              <FSTableRowItem :text="item.id" :truncate="-5" />

              <FSTableRowItem
                :text="item.user_full_name"
                :to="{
                  name: 'ViewRiderUserProfile',
                  params: { id: item.user_id },
                }"
                target="_blank"
              />

              <FSTableRowItem :text="item.num_of_trips_availed" />

              <FSTableRowItem :text="item.num_of_trips_left" />

              <FSTableRowItem
                :text="
                  getFormattedDateTime(item.created_at, 'D MMM, YYYY h:m a')
                "
              />

              <FSTableRowItem :text="getDiscountAmount(item.discount_amount)" />
            </FSTableRow>
          </template>
          <template v-if="getTableMode === 'responsive'">
            <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
              <FSTableRowItem>
                <div
                  class="col-span-1 focus:text-gray-400"
                  @click="toggle(itemIndex)"
                >
                  <i
                    class="fas fa-minus-circle"
                    style="color:#d90a20;"
                    v-if="opened.includes(itemIndex)"
                  ></i>
                  <i class="fas fa-plus-circle" v-else></i>
                </div>
              </FSTableRowItem>
              <FSTableRowItem
                :text="item.user_full_name"
                :to="{
                  name: 'ViewRiderUserProfile',
                  params: { id: item.user_id },
                }"
                target="_blank"
              />
              <FSTableRowItem :text="item.num_of_trips_availed" />
              <FSTableRowItem :text="getDiscountAmount(item.discount_amount)" />
            </FSTableRow>
            <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
              <td colspan="10">
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t('components.fleetManagement.table.columns.location')
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    #{{ item.id | onlyLastFive }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.promoManagement.details.promoHistory.table.columns.rentLeft'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{ item.num_of_trips_left || '--' }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.promoManagement.details.promoHistory.table.columns.date'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{
                      getFormattedDateTime(item.created_at, 'D MMM, YYYY h:m a')
                    }}
                  </div>
                </div>
              </td>
            </FSTableRow>
          </template>
        </template>
      </template>
    </FSTable>
  </ContentSection>
</template>

<script>
import TitlePlus from '@/components/ui/TitlePlus.vue'
import ContentSection from '@/components/layout/ContentSection'
import SummaryCard from '@/components/cards/SummaryCard'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import { getFormattedDateTime } from '@/utils/datetime'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  // FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'Vehicles',
  components: {
    ContentSection,
    TitlePlus,
    SummaryCard,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    // FSTableFilter,
  },
  props: {
    id: {
      required: false,
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          promo_users: 0,
          total_discounted: 0,
          trip_taken: 0,
        },
      },
      promoTypeChoice: {
        FR: 'Free Ride',
        FD: 'Flat Discount',
        RD: 'Rate Discount',
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.id'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.riderName'
          ),
          width: '20%',
          sort: 'user_full_name',
        },
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.rentAvailed'
          ),
          width: '15%',
          sort: 'num_of_trips_availed',
        },
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.rentLeft'
          ),
          width: '15%',
          sort: 'num_of_trips_left',
        },
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.date'
          ),
          width: '20%',
          sort: 'created_at',
        },
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.usedDiscountAmount'
          ),
          width: '20%',
          sort: 'discount_amount',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.riderName'
          ),
          width: '30%',
          sort: 'user_full_name',
        },
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.rentAvailed'
          ),
          width: '20%',
          sort: 'num_of_trips_availed',
        },
        {
          text: this.$t(
            'components.promoManagement.details.promoHistory.table.columns.usedDiscountAmount'
          ),
          width: '40%',
          sort: 'discount_amount',
        },
      ],
      total_trip_taken: '',
      discount_amount: '',
      start_date: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaryUsers() {
      return this.indexMetaData.summary.promo_users
    },
    getSummaryTrips() {
      return this.indexMetaData.summary.trip_taken
    },
    getSummaryDiscounts() {
      return `${this.currencySymbol} ${this.indexMetaData.summary.total_discounted}`
    },
  },

  methods: {
    getFormattedDateTime,
    onApplyFilterDateRange,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getPromoTypeChoice(choice) {
      return choice in this.promoTypeChoice ? this.promoTypeChoice[choice] : '-'
    },
    getDiscountAmount(amount) {
      return this.currencySymbol + ' ' + parseFloat(amount).toFixed(2)
    },
  },
}
</script>

<style lang="scss" scoped>
.promo-free-card {
  width: 220px;
  height: 80px;
  border-radius: 5px;
  background-color: #a600ff;
}
.promo-discount-card {
  width: 220px;
  height: 80px;
  border-radius: 5px;
  background-color: #ea8900;
  margin-left: 15px;
}
.promo-flat-card {
  width: 220px;
  height: 80px;
  border-radius: 5px;
  background-color: #23c977;
  margin-left: 15px;
}
.promo-card-title {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.promo-card-value {
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.promo-card-right {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.promotion-header {
  span {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e39;
  }
}
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
